export enum LOADING_IDS {
	BETSLIP_CASHOUT = 'getBetSlipCashOut',
	STATS_RACE = 'getRaceResults',
	STATS_LAST_RACE = 'getLastRaceResults',
	USER_STATS_RACE = 'getUserRaceData',
	USER_VIP_RAKE_BACK_CLAIM = 'USER_VIP_RAKE_BACK_CLAIM',
	VIP_LEVELS = 'VIP_LEVELS',
	VIP_STATUS = 'VIP_STATUS',
	VIP_WHEEL_CLAIM = 'VIP_WHEEL_CLAIM',
	VIP_WHEEL_FUNDS = 'VIP_WHEEL_FUNDS',
	FRIENDS_IGNORED = 'getFriendsIgnored',
	VAULT_HISTORY = 'getVaultHistory',
	SESSION_HISTORY = 'getSessionHistory',
	AFFILIATE_CAMPAIGNS = 'getAffiliateCampaigns',
	AFFILIATE_FUNDS = 'getAffiliateFunds',
	AFFILIATE_ANALYTICS = 'getAffiliateAnalytics',
	AFFILIATE_CASHOUT_HISTORY = 'getAffiliateCashoutHistory',
	BONUS_HISTORY = 'getBonusHistory',
	SUPPORT_APP = 'SUPPORT_APP',
	GET_SESSION_DATA = 'getSessionData',
	GET_BET_DATA = 'getBetData',
	GET_AUTOBET_STRATEGY = 'getAutobetStrategy',
	CLAIM_BONUS_CODE = 'CLAIM_BONUS_CODE',
	AVAILABLE_BONUS_CODES = 'availableBonusCodes',
	GET_USER_BETS = 'getUserBets',
	PLACE_BET = 'placeBet',
	SUBMIT_TIP = 'submitTip',
}

type LoadingId = keyof typeof LOADING_IDS;
export type LoadingIdType = (typeof LOADING_IDS)[LoadingId];
