import { FC, useCallback, useEffect, useState } from 'react';
import { servicesConfig } from '@server/config/services.config';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { domPurifySanitize } from '@common/methods/domPurifySanitize';
import { initLicense } from '../../modules/app/vendorService';
import { onLoad } from '../../modules/app/appService';
import { isFeatureAvailable } from '../../modules/app/featureService';
import { useViewport } from '../../../hooks/render/useViewport';
import { config, FEATURES_TYPE } from '../../config';

const LICENCE_ID = servicesConfig.licenseId;

const License_: FC = () => {
	const [element, save] = useState<string>(null);

	const getLicenseElement = useCallback(() => {
		// console.log('initLicense', window[licenseId.split('-').join('_')]);
		if (window[LICENCE_ID.split('-').join('_')]) {
			if (window.location.host !== config.domain && NEXT_CONFIG.PRODUCTION) {
				return;
			}
			if (process.browser) {
				// @ts-expect-error invalid type
				window?.[LICENCE_ID.split('-')?.join('_')]?.init();
			}
			setTimeout(() => {
				// console.log({element: document.getElementById(this.state.licenseId)});
				const element = document.getElementById(LICENCE_ID);
				if (element) {
					save(element.innerHTML);
				}
			}, 3000);
		}
	}, [save]);

	const init = useCallback(() => {
		if (!isFeatureAvailable(FEATURES_TYPE.LICENSE)) {
			return;
		}
		if (element) {
			return;
		}
		initLicense(() => {
			getLicenseElement();
		});
	}, [element, getLicenseElement]);

	const { active, ref } = useViewport({ rootMargin: '100px' });

	useEffect(() => {
		if (active && !element) {
			onLoad(init);
		}
	}, [active, element, init]);

	if (!isFeatureAvailable(MODAL_ID.LICENSE)) {
		return null;
	}

	return (
		<>
			<div
				ref={ref}
				className="license"
				dangerouslySetInnerHTML={{
					__html: domPurifySanitize(element || ''),
				}}
			/>
		</>
	);
};
export const License = () => {
	if (!isFeatureAvailable(FEATURES_TYPE.LICENSE)) {
		return null;
	}
	return <License_ />;
};
