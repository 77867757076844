import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '@legacyApp/client/modules/style/theme';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';

export const SeoPageContentWrapperGlobalStyled = createGlobalStyle<{
	theme: ThemeType;
}>`
	.seo-page-content-wrapper {
		h1:not([class*='text-style']) {
			${(props) =>
				fontSize({
					size: '24px',
					sizeDesktop: '26px',
					minWidth: props.theme.media.minWidthDesktop,
					sizeBigScreen: '30px',
				})};
		}

		h2:not([class*='text-style']) {
			${(props) =>
				fontSize({
					size: '20px',
					sizeDesktop: '23px',
					minWidth: props.theme.media.minWidthDesktop,
					sizeBigScreen: '26px',
				})};
			border-bottom: 1px solid ${(props) => props.theme.colors.tableBorder};
			padding-bottom: 10px;
		}

		h1:not([class*='text-style']),
		h2:not([class*='text-style']) {
			float: left;
			width: 100%;
			height: unset;
			margin: 0;
		}

		h3:not([class*='text-style']) {
			float: left;
			width: 100%;
			height: unset;
			margin: 0 0 15px;
			${(props) =>
				fontSize({
					size: '18px',
					sizeDesktop: '20px',
					minWidth: props.theme.media.minWidthDesktop,
					sizeBigScreen: '23px',
				})};
		}

		h4:not([class*='text-style']) {
			float: left;
			width: 100%;
			height: unset;
			margin: 0 0 12px;
			${(props) =>
				fontSize({
					size: '17px',
					sizeDesktop: '17px',
					minWidth: props.theme.media.minWidthDesktop,
					sizeBigScreen: '19px',
				})};
		}

		h5:not([class*='text-style']) {
			float: left;
			width: 100%;
			height: unset;
			margin: 0 0 10px;
			${(props) =>
				fontSize({
					size: '16px',
					sizeDesktop: '16px',
					minWidth: props.theme.media.minWidthDesktop,
					sizeBigScreen: '16px',
				})};
		}
	}
`;
