import { useFetchApi } from '@legacyApp/hooks/fetch/useFetchApi';
import { second } from '@legacyApp/client/modules/app/time';
import routingService from '@legacyApp/client/modules/app/routingService';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { useOnMount } from '@common/hooks/useOnMount';
import { sentryError } from '@legacyApp/client/modules/app/sentryService';
import { generateUrl, ROUTE } from '../routes';

export const useCheckAccessDenied = () => {
	const { fetchLocal } = useFetchApi();

	const fetch = async () => {
		const response = await fetchLocal({
			url: generateUrl(ROUTE.apiCheckAccess),
			fromState: 30 * second,
			disableErrorHandler: true,
		}).catch((error) => {
			sentryError(new Error('useCheckAccessDenied fetch error'), {
				error,
				url: generateUrl(ROUTE.apiCheckAccess),
				fromState: 30 * second,
				disableErrorHandler: true,
			});
			return null;
		});
		if (
			response?.status &&
			response?.text &&
			response?.text.indexOf('403 Forbidden') > -1 &&
			response?.status === 403
		) {
			routingService.redirect(`/${ROUTING_ID.ACCESS_DENIED}`, true, true);
		}
	};

	useOnMount(() => {
		fetch();
	});
};
