import { FC, useState } from 'react';
import styled from 'styled-components';

import { PageContentType } from '@common/components/text/SeoPageContent/PageContentType';
import {
	StyledContent,
	StyledContentWrapper,
} from '@common/components/text/Content/Content.styled';
import { Button } from '@ui/button';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { domPurifySanitize } from '@common/methods/domPurifySanitize';
import { SeoPageContentWrapperGlobalStyled } from '@common/components/text/SeoPageContent/SeoPageContentWrapper.global.styled';

const SeoPageContent: FC<PageContentType> = (props) => {
	const toggle = true;
	const { shadowColor } = props?.options || {};

	const [open, setOpen] = useState(!toggle);

	return (
		<>
			<SeoPageContentWrapperGlobalStyled />
			<StyledSeoContentWrapper
				className="seo-page-content-wrapper"
				$shadowColor={shadowColor}
				$open={open}
			>
				<div
					className="text-style-xl-medium"
					dangerouslySetInnerHTML={{
						__html: domPurifySanitize(props.header),
					}}
				/>

				<StyledContent $open={open}>
					<div
						className="text-style-md-regular"
						dangerouslySetInnerHTML={{
							__html: domPurifySanitize(props.description),
						}}
					/>
					{toggle && (
						<Button
							styleType={ButtonStyleType.SECONDARY}
							label={trans({ label: open ? 'Hide' : 'Open' })}
							onClick={() => setOpen(!open)}
						/>
					)}
				</StyledContent>
			</StyledSeoContentWrapper>
		</>
	);
};

export const StyledSeoContentWrapper = styled(StyledContentWrapper)<{
	$open: boolean;
	$shadowColor?: string;
}>`
	h1 {
		margin-bottom: 0 !important;
		height: unset !important;
	}

	h2 {
		margin: 20px 0 10px !important;
	}

	h3 {
		margin: 15px 0 10px !important;
	}

	h4,
	h5 {
		margin: 35px 0 10px !important;
	}

	a {
		text-decoration: underline;
	}

	ul,
	ol,
	menu {
		list-style: unset;
		margin: unset;
		padding: 0 0 0 25px;
		width: 100%;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}

	ul,
	ol,
	p {
		margin: 3px 0;
	}

	li {
		float: left;
		margin-bottom: 5px;
		width: 100%;

		> * {
			margin: 0 !important;
		}
	}

	table {
		margin: 1em;
		width: 100%;
		border-collapse: collapse;
		${(props) => `background-color: ${props.theme.colors.seoTableBackground};`}
		color: #ffffff;
	}

	table th,
	table td {
		padding: 10px;
		text-align: center;
		${(props) => `border: 1px solid ${props.theme.colors.tableBorderLight};`}
	}

	table th {
		${(props) =>
			`background-color: ${props.theme.colors.seoTableBackgroundTh};`}
		font-weight: bold;
	}

	table tbody tr:nth-child(odd) {
		${(props) =>
			`background-color: ${props.theme.colors.seoTableBackgroundTdOdd};`}
	}

	table tbody tr:nth-child(even) {
		${(props) =>
			`background-color: ${props.theme.colors.seoTableBackgroundTdEven};`}
	}

	table tbody tr:hover {
		${(props) =>
			`background-color: ${props.theme.colors.seoTableBackgroundHover};`}
	}

	table td:first-child {
		font-weight: bold;
	}
`;

export default SeoPageContent;
