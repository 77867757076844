export const mapCaptchaLanguage = (lang) => {
	if (!lang) {
		return 'en';
	}
	if (lang === 'jp') {
		return 'ja';
	}
	if (lang === 'ph') {
		return 'tl';
	}
	return lang;
};
