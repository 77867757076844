import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { closeModal, openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { isFunction } from '@common/methods/isFunction';
import { loaderThunk } from '@legacyApp/client/store/loader/loader.actions';
import { epic } from '../../modules/app/epicService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import {
	activate2fa,
	saveQrcode,
	saveSecret,
	uninstall2fa,
} from './twoFactorAuthentication.actions';
import {
	CODE_REQUIRED,
	DISABLE_2FA,
	ENABLE_2FA,
	INSTALL_2FA,
} from './twoFactorAuthentication.constants';

const codeRequiredEpic = epic('codeRequiredEpic', {
	actions: (ofType) => ofType(CODE_REQUIRED),
	callback: ({ action, store$ }) => {
		if (store$.value.twoFA.isActive) {
			return of(
				openModal(MODAL_ID.REQUIRED_2FA, undefined, {
					url: action.url,
					parameters: action.parameters,
					method: action.method,
					resolve: action.resolve,
					loaderId: action.loaderId,
				}),
			);
		}
		if (isFunction(action.resolve)) {
			action.resolve(false);
		}
		return of([
			openModal(MODAL_ID.REMIND_2FA, 'fetchLocal', {
				text: {
					label:
						'Due to security of your funds, this feature is not available with 2FA disabled on your account. Please, enable 2FA to continue.',
				},
			}),
			loaderThunk(false, action.loaderId),
		]);
	},
});

const installEpic = epic('installEpic', {
	actions: (ofType) => ofType(INSTALL_2FA),
	callback: () => {
		return of(
			fetchApiAction(
				{
					url: '/2fa/install',
					parameters: {
						Authorization: true,
					},
					loaderId: 'install2fa',
					lockByModal: true,
				},
				(data) => {
					if (data.qrcode && data.secret) {
						return [
							openModal(MODAL_ID.INSTALL_2FA),
							saveQrcode(data.qrcode),
							saveSecret(data.secret),
						];
					}
					return [];
				},
			),
		);
	},
});

const enableEpic = epic('enableEpic', {
	actions: (ofType) => ofType(ENABLE_2FA),
	callback: ({ action }) => {
		return of(
			fetchApiAction(
				{
					url: '/2fa/enable',
					parameters: {
						Authorization: true,
						body: {
							...action.payload,
						},
					},
					method: 'POST',
					loaderId: 'enable2fa',
					lockByModal: true,
				},
				(data) => {
					let result = [];
					if (data.message.toLowerCase().indexOf('success') > -1) {
						result.push(closeModal());
						result.push(activate2fa());
					}
					return result;
				},
			),
		);
	},
});

const disableEpic = epic('disableEpic', {
	actions: (ofType) => ofType(DISABLE_2FA),
	callback: ({ action }) => {
		return of(
			fetchApiAction(
				{
					url: '/2fa/disable',
					parameters: {
						Authorization: true,
						body: {
							...action.payload,
						},
					},
					method: 'POST',
					loaderId: 'disable2fa',
					lockByModal: true,
				},
				(data) => {
					let result = [];
					if (data.message.toLowerCase().indexOf('success') > -1) {
						result.push(closeModal());
						result.push(uninstall2fa());
					}
					return result;
				},
			),
		);
	},
});

const twoFactorAuthenticationEpic = combineEpics(
	installEpic,
	enableEpic,
	disableEpic,
	codeRequiredEpic,
);

export { twoFactorAuthenticationEpic };
