import { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react';

import { useAppLanguage } from '@common/selectors/app.selectors';
import { languageConfig } from '@server/config/language.config';

const languages = languageConfig.available.map((config) => config.id);

const useHref = (href: string, noLocale: boolean): string => {
	const language = useAppLanguage();

	if (noLocale) {
		return href;
	}

	if (language === languageConfig.default) {
		return href;
	}

	if (!href) {
		return href;
	}

	if (href.indexOf('http') > -1 || href.indexOf('mailto:') > -1) {
		return href;
	}

	if (languages.some((lang) => href.indexOf(`/${lang}`) === 0)) {
		return href;
	}

	return `${language ? `/${language}` : ''}${href}`;
};

export const Redirect: FunctionComponent<
	PropsWithChildren<{
		href: string;
		target?: string;
		rel?: string;
		className?: string;
		onClick?: MouseEventHandler<HTMLElement>;
		style?: any;
		noLocale?: boolean;
	}>
> = ({ noLocale, href, children, target, rel, className, onClick, style }) => {
	const link = useHref(href, noLocale);

	return (
		<a
			onClick={onClick}
			className={className}
			target={target}
			rel={rel}
			href={link}
			style={style}
		>
			{children}
		</a>
	);
};
