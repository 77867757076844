import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import { useUserIsAdmin } from '@common/selectors/user.selectors';
import sortService, {
	DIRECTION,
} from '@legacyApp/client/modules/app/sortService';
import {
	trans,
	TransSlots,
	transSlots,
} from '@legacyApp/client/modules/translation/translate';
import { useFetchProviders } from '@modules/slots/hooks/useFetchProviders';
import { SORT_OPTION } from '@modules/slots/layouts/SlotCategory.layout';
import { ProviderDTO } from '@modules/slots/store/slots.types';
import { simpleTrans } from '@legacyApp/client/modules/translation/translationService';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';

import {
	FilterDropdown,
	FilterDropdownElement,
} from '@common/components/filterDropdown/FilterDropdown';
import { Checkbox } from '@legacyApp/client/components/checkbox';
import { CSSClassNameButton } from '@ui/button';
import { DropdownArrowParentMixin } from '@ui/dropdown/DropdownArrow';

interface SlotsFiltersProps {
	name: string;
	setFilterProvider?: (provider: number, add: boolean) => void;
	setFilterSort: (sort: Option) => void;
	filterSort: Option;
	filterProviders?: string[];
}

export type Option = {
	label: string;
	value: string;
};

export const StyledFilterContainer = styled.div`
	align-items: center;
	display: flex;
	margin-left: 0.5rem;
	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		:first-of-type {
			margin-left: 0;
		}
		justify-content: space-between;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthVerySmall}) {
		.${CSSClassNameButton} {
			padding: 7px 10px;
		}
	}
`;

export const StyledFilterSelect = styled.div`
	.${CSSClassNameButton} {
		${DropdownArrowParentMixin};
	}
`;

export const SlotsFilters: FC<SlotsFiltersProps> = ({
	setFilterSort,
	setFilterProvider,
	filterSort,
	filterProviders,
}) => {
	const { callback, options } = useFetchProviders();

	const userIsAdmin = useUserIsAdmin();

	const { data: providers } = useGetDataArray<ProviderDTO>(
		`providers-${userIsAdmin}`,
		callback,
		options,
	);

	const _setFilterSort = useCallback(
		(item: FilterDropdownElement) => {
			setFilterSort({
				value: item.id,
				label: item.label,
			});
		},
		[setFilterSort],
	);

	return (
		<>
			{filterProviders && (
				<FilterDropdown
					id={'slot-filter-providers'}
					list={providers
						.sort((a, b) => sortService.sort(DIRECTION.ASC, a.name, b.name))
						.map((provider) => ({
							id: String(provider.id),
							element: (
								<Checkbox
									label={<span>{provider.name}</span>}
									onChange={(checked) => {
										setFilterProvider(provider.id, checked);
									}}
								/>
							),
						}))}
				>
					{filterProviders.length > 0 ? (
						<span>
							{transSlots({ label: 'Providers' })}
							{`: ${filterProviders.length}`}
						</span>
					) : (
						<TransSlots label={'All Providers'} />
					)}
				</FilterDropdown>
			)}

			<FilterDropdown
				id={'slot-filter-sort-by'}
				onClick={_setFilterSort}
				list={SORT_OPTION.map((item) => ({
					id: item.value,
					element: (
						<div className="text-capitalize">
							{trans({ label: item.label })}
						</div>
					),
					label: item.label,
				}))}
			>
				<>{`${simpleTrans('Sort by')}: ${simpleTrans(filterSort.label)}`}</>
			</FilterDropdown>
		</>
	);
};
