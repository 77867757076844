import { config } from '@legacyApp/client/config';
import { getPathLocalized } from '@common/methods/getPathLocalized/getPathLocalized';
import { getPathnameWithoutLang } from '@common/methods/getPathnameWithoutLang/getPathnameWithoutLang';

export const createDomainPath = (pathname: string, locale: string) => {
	const pathname_ = pathname.split('?')[0];
	return `https://${config.domain}${getPathLocalized({
		pathname: getPathnameWithoutLang(pathname_, locale),
		locale,
	})}`;
};
